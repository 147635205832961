.bwhtsx5{--icon-hover-color:var(--color-text);position:relative;display:-ms-grid;display:grid;-ms-grid-columns:24rem 1fr;grid-template-columns:24rem 1fr;gap:32px 96px;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:end;-webkit-box-align:end;-ms-flex-align:end;align-items:end;padding-bottom:40px;font-size:0.875rem;}@media (max-width: 55rem){.bwhtsx5{-ms-grid-columns:22rem 1fr;grid-template-columns:22rem 1fr;gap:32px 32px;}}@media (max-width: 48rem){.bwhtsx5{-ms-grid-columns:1fr;grid-template-columns:1fr;-webkit-box-pack:revert;-ms-flex-pack:revert;-webkit-justify-content:revert;justify-content:revert;-webkit-align-items:revert;-webkit-box-align:revert;-ms-flex-align:revert;align-items:revert;}}
.w12sqewt.bwhtsx5{grid-template-areas:'intro links' 'legal links';}@media (max-width: 48rem){.w12sqewt.bwhtsx5{grid-template-areas:'intro' 'links' 'legal';}}
.wd6hsrp.bwhtsx5{grid-template-areas:'intro links' 'email links' 'legal links';}@media (max-width: 48rem){.wd6hsrp.bwhtsx5{grid-template-areas:'intro' 'email' 'links' 'legal';}}
.iwnpaet{grid-area:intro;}
.s19hmjl2{grid-area:email;}
.s11c2unw{margin-top:8px;}
.lqsyp1m{grid-area:links;display:-ms-grid;display:grid;grid-template-areas:'cats courses misc' 'cats social social';-ms-grid-columns:10rem 10rem 6.5rem;grid-template-columns:10rem 10rem 6.5rem;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;gap:16px 64px;}@media (max-width: 67.125rem){.lqsyp1m{grid-template-areas:'courses misc' 'social social';-ms-grid-columns:10rem 6.5rem;grid-template-columns:10rem 6.5rem;-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:end;justify-content:end;}}@media (max-width: 48rem){.lqsyp1m{-webkit-box-pack:start;-ms-flex-pack:start;-webkit-justify-content:start;justify-content:start;-ms-grid-columns:10rem 1fr;grid-template-columns:10rem 1fr;gap:32px 32px;max-width:20.625rem;}}@media (max-width: 28rem){.lqsyp1m{max-width:revert;}}@media (max-width: 21rem){.lqsyp1m{grid-template-areas:'courses' 'misc' 'social';-ms-grid-columns:1fr;grid-template-columns:1fr;}}
.c1ryx62z{grid-area:cats;}@media (max-width: 67.125rem){.c1ryx62z{display:none;}}
.cewaxi0{grid-area:courses;}
.m1p8ktpj{grid-area:misc;}
.s1jnxy9z{grid-area:social;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;color:var(--secondary-text);-webkit-transform:translateY(40%);-moz-transform:translateY(40%);-ms-transform:translateY(40%);transform:translateY(40%);}@media (max-width: 21rem){.s1jnxy9z{-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;gap:0px;}}
.m1e3nesg{display:block;margin:0;margin-bottom:0.5rem;padding-bottom:0.5rem;font-size:inherit;font-weight:var(--font-weight-medium);text-transform:uppercase;color:var(--secondary-text);}@media (max-width: 21rem){.m1e3nesg{text-align:center;}}.m1p8ktpj .m1e3nesg{text-align:right;}@media (max-width: 21rem){.m1p8ktpj .m1e3nesg{text-align:center;}}
.lfmb6v3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;padding:0;list-style-type:none;}@media (max-width: 21rem){.lfmb6v3{text-align:center;}}.m1p8ktpj .lfmb6v3{text-align:right;}@media (max-width: 21rem){.m1p8ktpj .lfmb6v3{text-align:center;}}
.l1n09z53{grid-area:legal;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:4px;margin-top:16px;color:var(--secondary-text);font-size:0.75rem;}
.cmsru6n{display:block;margin:0;}
.lsaasn7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;padding:0;list-style-type:none;}
.ll46nlp{position:relative;}
.lmb90g4{color:inherit;-webkit-text-decoration:underline;text-decoration:underline;}.lmb90g4:hover{-webkit-text-decoration:none;text-decoration:none;}
