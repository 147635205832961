.w17lbfx3{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;font-size:1.5rem;letter-spacing:-0.0625rem;padding:0;-webkit-text-decoration:none;text-decoration:none;color:var(--color-primary);}
.gr874hq{display:inline-block;font-weight:var(--font-weight-medium);}
@media (prefers-reduced-motion: no-preference){.goxpqke.gr874hq{-webkit-animation:scoochLeft-goxpqkegr874hq 300ms 750ms cubic-bezier(0.27, 0.22, 0.44, 1.03) both;animation:scoochLeft-goxpqkegr874hq 300ms 750ms cubic-bezier(0.27, 0.22, 0.44, 1.03) both;}}@-webkit-keyframes scoochLeft-goxpqkegr874hq{from{-webkit-transform:translateX(6px);-moz-transform:translateX(6px);-ms-transform:translateX(6px);transform:translateX(6px);}to{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);}}@keyframes scoochLeft-goxpqkegr874hq{from{-webkit-transform:translateX(6px);-moz-transform:translateX(6px);-ms-transform:translateX(6px);transform:translateX(6px);}to{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);}}
.f5ggs7{display:inline-block;font-weight:var(--font-weight-medium);}
@media (prefers-reduced-motion: no-preference){.f7ysafc.f5ggs7{-webkit-animation:scoochRight-f7ysafcf5ggs7 300ms 825ms cubic-bezier(0.27, 0.22, 0.44, 1.03) both;animation:scoochRight-f7ysafcf5ggs7 300ms 825ms cubic-bezier(0.27, 0.22, 0.44, 1.03) both;}}@-webkit-keyframes scoochRight-f7ysafcf5ggs7{from{-webkit-transform:translateX(-6px);-moz-transform:translateX(-6px);-ms-transform:translateX(-6px);transform:translateX(-6px);}to{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);}}@keyframes scoochRight-f7ysafcf5ggs7{from{-webkit-transform:translateX(-6px);-moz-transform:translateX(-6px);-ms-transform:translateX(-6px);transform:translateX(-6px);}to{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);}}
.l1ngw3bx{position:absolute;left:6px;bottom:0;}
@media (prefers-reduced-motion: no-preference){.l1c4qjpw.l1ngw3bx path{stroke-dasharray:18;stroke-dashoffset:18;-webkit-animation:selfDraw-l1c4qjpwl1ngw3bx 150ms 1050ms both;animation:selfDraw-l1c4qjpwl1ngw3bx 150ms 1050ms both;}}@-webkit-keyframes selfDraw-l1c4qjpwl1ngw3bx{to{stroke-dashoffset:0;}}@keyframes selfDraw-l1c4qjpwl1ngw3bx{to{stroke-dashoffset:0;}}
.i9xec9h{position:absolute;left:2px;top:-1px;}
@media (prefers-reduced-motion: no-preference){.idwm85l.i9xec9h path{stroke-dasharray:26;stroke-dashoffset:26;-webkit-animation:selfDraw-idwm85li9xec9h 600ms 1200ms both cubic-bezier(0.27, 0.22, 0.44, 1.03);animation:selfDraw-idwm85li9xec9h 600ms 1200ms both cubic-bezier(0.27, 0.22, 0.44, 1.03);}}@-webkit-keyframes selfDraw-idwm85li9xec9h{to{stroke-dashoffset:0;}}@keyframes selfDraw-idwm85li9xec9h{to{stroke-dashoffset:0;}}
.p16e8bzl{stroke:var(--color-gray-700);}
.irj294a{display:inline-block;position:relative;width:18px;}
.i100qmoq{opacity:0;}
