.o1xij0rw{position:relative;}
.w2bo5dh{position:relative;padding-bottom:16px;min-height:7.25rem;overflow-y:clip;}
.i10pfxxo{font-size:0.875rem;margin-bottom:1em;cursor:default;max-width:25rem;}
.r1jmr965{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;background:var(--color-background);max-width:22rem;border-radius:8px;padding:4px;margin-left:-12px;margin-right:-12px;}.r1jmr965:has(input:focus-visible){background:var(--color-background);outline-color:Highlight;outline-color:-webkit-focus-ring-color;outline-style:auto;outline-width:3px;outline-offset:2px;}
.i1vzjbo1{-webkit-flex:1;-ms-flex:1;flex:1;background:transparent;border:none;outline:none;font-size:1rem;padding:4px 8px;}
.b1i6gnav{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:2.25rem;height:2.25rem;background:black;color:white;border-radius:4px;font-size:0.875rem;-webkit-transition:color var(--color-swap-duration),background var(--color-swap-duration);transition:color var(--color-swap-duration),background var(--color-swap-duration);overflow:hidden;overflow:clip;}html[data-color-mode='dark'] .b1i6gnav{background:hsl(50deg 100% 50%);background:oklch(0.9 0.22 97.52);color:black;}
.p1yz2o0p{position:absolute;top:0;right:0;width:120%;height:100%;-webkit-clip-path:polygon(
    0% 0%,
    var(--progress) 0%,
    calc(var(--progress) + 20%) 100%,
    0% 100%
  );clip-path:polygon(
    0% 0%,
    var(--progress) 0%,
    calc(var(--progress) + 20%) 100%,
    0% 100%
  );}html[data-color-mode='light'] .p1yz2o0p{background:hsl(200deg 70% 70% / 0.4);}html[data-color-mode='dark'] .p1yz2o0p{background:hsl(0deg 0% 100% / 0.8);}
.arcsmi4{position:absolute;inset:0;margin:auto;-webkit-transition:-webkit-transform 300ms;transition:transform 300ms;}
.s1elub26{position:absolute;inset:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-animation:enter-s1elub26 500ms both;animation:enter-s1elub26 500ms both;-webkit-animation-delay:200ms;animation-delay:200ms;}@-webkit-keyframes enter-s1elub26{from{opacity:0;-webkit-transform:scale(0.25);-moz-transform:scale(0.25);-ms-transform:scale(0.25);transform:scale(0.25);}}@keyframes enter-s1elub26{from{opacity:0;-webkit-transform:scale(0.25);-moz-transform:scale(0.25);-ms-transform:scale(0.25);transform:scale(0.25);}}
.e11cm64s{--color-primary:var(--color-error-500);background:var(--color-error-100);max-width:22rem;border-radius:8px;padding:12px;margin-left:-12px;margin-top:4px;-webkit-animation:dropIn-e11cm64s 400ms cubic-bezier(0.23, 0.62, 0.41, 1.01) both;animation:dropIn-e11cm64s 400ms cubic-bezier(0.23, 0.62, 0.41, 1.01) both;-webkit-animation-delay:200ms;animation-delay:200ms;}.e11cm64s p{margin-bottom:1em;font-size:0.875rem;color:var(--color-error-500);text-align:center;-webkit-animation:fadeIn 300ms 400ms both;animation:fadeIn 300ms 400ms both;}.e11cm64s p:last-child{margin-bottom:0;}.e11cm64s a{color:inherit;}@-webkit-keyframes dropIn-e11cm64s{from{opacity:0;-webkit-transform:translateY(-6px);-moz-transform:translateY(-6px);-ms-transform:translateY(-6px);transform:translateY(-6px);}}@keyframes dropIn-e11cm64s{from{opacity:0;-webkit-transform:translateY(-6px);-moz-transform:translateY(-6px);-ms-transform:translateY(-6px);transform:translateY(-6px);}}
